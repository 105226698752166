body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* navbar */

nav {
  background-color: #f8f9fa;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

nav li {
  margin: 0 1.5rem;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  color: #343a40;
  transition: color 0.3s;
}

nav a:hover {
  color: #007bff;
}

/* main */

.App {
  text-align: center;
  font-family: 'Arial, sans-serif';
}

.title-center {
  text-align: center;
}

.title-h1h2 h1, 
.title-h1h2 h2 {
  margin: 0;
}

.App-section {
  margin: 20px;
  text-align: left;
}

.App-section h3 {
  margin-bottom: 10px;
}

.App-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.App-section ul li {
  margin-bottom: 5px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

li {
  list-style-type: none;
}